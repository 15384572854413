<template>
  <div class="d-flex justify-content-center">
    <div class="card col-sm-6">
      <div class="card-body">
        <h5 class="text-center text-uppercase text-primary font-weight-bold m-1">{{ $t('Welcome to shifton!') }}</h5><br/>
        <template v-if="registrationAsOwner === null">
          <div class="row">
            <div class="col-md-6 p-1">
              <button
                class="btn btn-outline-primary btn-block py-5"
                aria-label="Close"
                @click="registrationAsOwner = true">
                <i class="icon-business mx-1 align-middle" style="font-size: 2.5rem"></i>
                <span class="align-middle" style="font-size: 1.2rem">
                  {{ $t('I_AM_OWNER_REGISTER') }}
                </span>
              </button>
            </div>
            <div class="col-md-6 p-1">
              <button
                class="btn btn-outline-primary btn-block py-5"
                aria-label="Close"
                @click="registrationAsOwner = false">
                <i class="icon-users mx-1 align-middle" style="font-size: 2.5rem" />
                <span class="align-middle" style="font-size: 1.2rem">
                  {{ $t('I_AM_EMPLOYEE_REGISTER') }}
                </span>
              </button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto pt-4">
              <span>{{ $t("Already have an account?") }}
                <router-link
                  :to="`/login?lang=${registrationData.locale}`"
                  class="">{{ $t('Sign In') }}
                </router-link>
              </span>
            </div>
          </div>
        </template>
        <template v-if="registrationAsOwner === true && registrationData.work_mode === null">
          <div class="row">
            <div class="col-md-6 p-1">
              <button
                class="btn btn-outline-primary btn-block py-5"
                aria-label="Close"
                @click="setWorkMode('work')">
                <el-icon :size="40" class="mx-1 align-middle">
                  <SetUp />
                </el-icon>
                <span class="align-middle" style="font-size: 1.2rem">
                  {{ $t('REGISTER_AS_SHIFTON_WORK') }}
                </span>
              </button>
              <div class="mt-2">
                  <div class="card card-body" v-html="$t('REGISTRATION_SHIFTON_WORK_INFO')">
                  </div>
              </div>
            </div>
            <div class="col-md-6 p-1">
              <button
                class="btn btn-outline-primary btn-block py-5"
                aria-label="Close"
                @click="setWorkMode('field_service')">
<!--                <i class="icon-users mx-1 align-middle" style="font-size: 2.5rem" />-->
                <el-icon :size="40" class="mx-1 align-middle">
                  <MapLocation />
                </el-icon>
                <span class="align-middle" style="font-size: 1.2rem">
                  {{ $t('REGISTER_AS_FIELD_SERVICE') }}
                </span>
              </button>
              <div class="mt-2">
                  <div class="card card-body" v-html="$t('REGISTRATION_FIELD_SERVICE_INFO')">
                  </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto pt-4">
              <span>{{ $t("Already have an account?") }}
                <router-link
                  :to="`/login?lang=${registrationData.locale}`"
                  class="">{{ $t('Sign In') }}
                </router-link>
              </span>
            </div>
          </div>
        </template>
        <template v-if="registrationAsOwner === true && registrationData.work_mode !== null">
          <Form
            ref="form"
            id="form_registration"
            @submit="SubmitRegisterForm"
          >
            <div class="row mb-2"> <!-- form header here -->
              <div class="col">
                <h6 class="text-center">{{ $t('Registration') }}</h6>
              </div>
            </div>
            <template v-if="registrationData.account_type">
              <div class="row">
                <div class="form-group col-md">
                  <ui-text-input
                    id="registerInputFirstName"
                    name="first_name"
                    v-model="registrationData.first_name"
                    :validation="{required: true, max: 128}"
                    :placeholder="this.$i18n?.t('Enter first name')"
                    :label="$t('First name')"
                  />
                </div>
                <div class="form-group col-md">
                  <ui-text-input
                    id="registerInputLastName"
                    name="last_name"
                    v-model="registrationData.last_name"
                    :validation="{required: true, max: 128}"
                    :placeholder="this.$i18n?.t('Enter last name')"
                    :label="$t('Last name')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md">
                  <ui-text-input
                    id="registerInputEmail"
                    name="email"
                    v-model="registrationData.email"
                    :validation="{required: true, email: true}"
                    :placeholder="this.$i18n?.t('Enter your e-mail')"
                    :label="'E-mail'"
                  />
                </div>
                <div class="form-group col-md">
                  <div class="input-group">
                    <ui-tel-input
                      :label="$t('Phone number')"
                      class="w-100"
                      v-model="registrationData.number"
                      name="phone"
                      id="registerInputPhone"
                      :validation="{required: true, max: 20}"
                    />
                  </div>
                </div>
              </div>
              <template v-if="registrationData.account_type==='company'">
                <div class="row">
                  <div class="form-group col-md">
                    <ui-text-input
                      id="companyName"
                      name="companyName"
                      v-model="registrationData.name"
                      :validation="{required: true, min: 3, max: 128}"
                      :placeholder="this.$i18n?.t('Enter company name')"
                      :label="$t('Company name')"
                    />
                  </div>
                  <div class="form-group col-md">
                    <ui-select
                      id="tz"
                      name="tz"
                      v-model="registrationData.time_zone"
                      :clearable="false"
                      :filterable="true"
                      :placeholder="$t('Select')"
                      :label="$t('Time zone')"
                      :options="allZones"
                      :key-name="'id'"
                      :label-name="'name'"
                      :value-name="'id'"
                      :validation="{required: true}"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md">
                    <ui-select
                      id="companyWorkers"
                      name="companyWorkers"
                      v-model="registrationData.workers"
                      :clearable="false"
                      :filterable="true"
                      :placeholder="$t('Select')"
                      :label="$t('Company workers')"
                      :options="companyWorkersChoice"
                      :validation="{required: true}"
                    />
                  </div>
                  <div class="form-group col-md">
                    <ui-select
                      id="companyIndustry"
                      name="companyIndustry"
                      v-model="registrationData.industry"
                      :clearable="false"
                      :filterable="true"
                      :placeholder="$t('Select')"
                      :label="$t('Industry')"
                      :options="companyIndustryChoice"
                      :validation="{required: true}"
                    />
                  </div>
                </div>
              </template>
              <div class="row">
                <div class="form-group col-md">
                  <ui-text-input
                    id="registerInputPassword"
                    type="password"
                    name="password"
                    v-model='registrationData.password'
                    :validation="{required: true, password: true, min: 8}"
                    :placeholder="this.$i18n?.t('Enter password')"
                    :label="$t('Password')"
                  />
                </div>
                <div class="form-group col-md">
                  <ui-text-input
                    id="registerPromoCode"
                    name="promo_code"
                    v-model='registrationData.promo_code'
                    :validation="{required: false, min: 3, max: 50}"
                    :placeholder="this.$i18n?.t('Enter Promo Code')"
                    :label="$t('Promo Code')"
                  />
                </div>
              </div>
              <div class="row justify-content-center">
                <el-alert
                  v-if="showErrorAlert"
                  :title="$t(alertText)"
                  type="error"
                  effect="dark"
                  :closable="false"
                  class="mb-2"
                >
                </el-alert>
                <div class="">
                  <button
                    class="btn btn-success btn-circle btn-block shadow-sm"
                    :disabled="clicked"
                    id="registerSubmitFormButton"
                    type="submit"
                  >{{ $t('Sign Up') }}</button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto p-2">
                  <small
                    id="registerSubmitHelpBlock"
                    class="form-text text-muted"
                  >
                    {{ $t('By clicking Signup, you agree to our') }}
                    <a :href="this.$root.redirectUrl + $i18n.locale + '/terms'">{{ $t('Terms of Use') }}</a>
                  </small>
                </div>
              </div>
            </template>
            <div class="row justify-content-center">
              <div class="col-auto pt-4">
              <span>{{ $t("Already have an account?") }}
                <router-link
                  :to="`/login?lang=${registrationData.locale}`"
                  class="">{{ $t('Sign In') }}
                </router-link>
              </span>
              </div>
            </div>
            <div class="row justify-content-center mt-2">
              <div class="col-12 text-center">
                <button
                  class="btn btn-outline-primary btn-circle"
                  aria-label="Close"
                  @click="() => {
                    this.registrationAsOwner = false
                    this.registrationData.work_mode = null
                  }">
                  {{ $t('I_AM_EMPLOYEE_REGISTER') }}
                </button>
              </div>
            </div>
          </Form>
        </template>
        <template v-if="registrationAsOwner === false">
          <div class="py-5 text-center font-weight-bold">
            {{$t('EMPLOYEE_REGISTER_TEXT')}}
          </div>
          <div class="row justify-content-center">
            <div class="col-auto pt-4">
              <span>{{ $t("Already have an account?") }}
                <router-link
                  :to="`/login?lang=${registrationData.locale}`"
                  class="">{{ $t('Sign In') }}
                </router-link>
              </span>
            </div>
          </div>
          <div class="text-center mt-2">
            <button
              class="btn btn-outline-primary btn-circle"
              aria-label="Close"
              @click="() => {
                    this.registrationAsOwner = true
                    this.registrationData.work_mode = null
                  }">
              {{ $t('I_AM_OWNER_REGISTER') }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import analyticsMixin from '@/mixins/analyticsMixin'
import { mapState, mapGetters } from 'vuex'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'

import IMG from '@/assets/images/logo.svg'
import moment from 'moment-timezone'

export default {
  name: 'Registration',
  mixins: [errorMixin, momentMixin, mixinWindowWidthWatch, analyticsMixin],
  data () {
    return {
      companyWorkersChoice: [
        {
          id: '1-20',
          name: '1-20'
        },
        {
          id: '21-50',
          name: '21-50'
        }, { id: '51-100', name: '51-100' },
        { id: '100-300', name: '100-300' },
        { id: '300-1000', name: '300-1000' },
        { id: '1000+', name: '1000+' }
      ],
      companyIndustryChoice: [],
      phoneCodeChoice: [],
      showErrorAlert: false,
      alertText: '',
      registrationData: {
        account_type: 'company',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        locale: 'en',
        recaptcha_key: '',
        phoneCode: '1',
        number: '',
        workers: '',
        name: '',
        industry: '',
        time_zone: null,
        promo_code: null,
        sbjs_data: {},
        work_mode: null
      },
      date_birth: new Date(),
      languageCodes: ['en', 'ru', 'he', 'uk', 'de', 'pl', 'es', 'el'],
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      access_token: null,
      response: null,
      clicked: false,
      dateFormat: 'dd/MM/yyyy',
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      captchaSize: 'normal',
      registrationAsOwner: null
    }
  },
  created () {
    if (this.$route.query.f) {
      localStorage.setItem('agentId', this.$route.query.f)
    }
    if (this.languageCodes.includes(this.$route.query.lang)) {
      localStorage.setItem('language', this.$route.query.lang)
      this.registrationData.locale = this.$route.query.lang
    }

    if (['work', 'field_service'].includes(this.$route.query.work_mode)) {
      this.registrationAsOwner = true
      this.setWorkMode(this.$route.query.work_mode)
    }

    this.$store.dispatch('getTimeZones')
    this.$store.dispatch('getTimeZone').then(response => {
      this.registrationData.time_zone = response
    }).catch(() => {
      this.registrationData.time_zone = moment.tz.guess()
    })

    this.$store.dispatch('translationsByType', [this.registrationData.locale, 'industry']).then((response) => {
      this.companyIndustryChoice = []
      Object.keys(response).forEach((key) => {
        this.companyIndustryChoice.push({
          id: key,
          name: response[key]
        })
      })
    }).catch(() => {})
  },
  beforeMount () {
    if (this.isMobile) this.captchaSize = 'compact'
  },
  mounted () {
    try {
      if (process.env.VUE_APP_GTM_IS_ENABLE === 'true') {
        this.$gtm.trackView('Registration', 'registration')
      }
    } catch (e) {
      console.log(e)
    }
    if (this.isMobile) {
      this.$alert('<div><img src="' + IMG + '" width="200"></div>' + '<div class="mt-2">' + this.$t('USE_DESKTOP_WARNING') + '</div>', this.$t('USE_DESKTOP_WARNING_TITLE'), {
        confirmButtonText: this.$t('OK'),
        dangerouslyUseHTMLString: true,
        customClass: 'w-100',
        center: true,
        callback: action => {}
      })
    }
  },
  watch: {
    async '$recaptchaInstance.recaptcha' () {
      this.$recaptchaInstance.showBadge()
    },
    email () {
      this.registrationData.email = this.registrationData.email.trim()
    },
    $route (to, from) {
      if (this.languageCodes.includes(this.$route.query.lang)) {
        this.registrationData.locale = this.$route.query.lang
        this.$store.dispatch('translationsByType', [this.registrationData.locale, 'industry']).then((response) => {
          this.companyIndustryChoice = []
          Object.keys(response).forEach((key) => {
            this.companyIndustryChoice.push({
              id: key,
              name: response[key]
            })
          })
        }).catch(() => {})
      }
    }
  },
  computed: {
    email () {
      return this.registrationData.email
    },
    ...mapGetters(['allZones']),
    ...mapState({
      apiErrors: state => state.apiErrors
    })
  },
  methods: {
    setWorkMode (workMode) {
      if (workMode !== this.$route.query.work_mode) {
        this.$router.push({ query: { ...this.$route.query, work_mode: workMode } }).catch((e) => {})
      }
      this.registrationData.work_mode = workMode
    },
    SubmitRegisterForm () {
      this.$refs.form.validate().then(response => {
        if (response.valid) {
          this.handleRegistration()
        }
      })
    },
    async handleRegistration () {
      this.clicked = true
      this.registrationData.sbjs_data = this.getCookie('sbjs_current')
      await this.$recaptchaLoaded()
      if (!this.recaptchaShow) {
        this.registrationData.recaptcha_key = this.sitekey
      } else {
        this.registrationData.recaptcha_key = await this.$recaptcha('registration_in_shifton')
      }
      this.registrationData.phone = this.parsePhoneNumber(this.registrationData.number)
      this.registrationData.platform = navigator.platform
      const agentId = localStorage.getItem('agentId')
      if (agentId) {
        this.registrationData.agent_id = agentId
      }
      this.$store.dispatch('register', this.registrationData).then((response) => {
        this.trackEvent({
          gtm: {
            event: 'registration',
            category: 'SignUp',
            action: 'click',
            label: 'General'
          },
          crisp: {
            eventName: 'company:register'
          }
        })
        localStorage.removeItem('agentId')
        this.clicked = false
        // get profile and set basic data
        this.$store.dispatch('getProfile').then((ProfileData) => {
          const company = ProfileData.companies[0]
          localStorage.setItem('currentCompany', company)
          if (this.registrationData.account_type === 'company' && this.registrationData.work_mode === 'work') {
            this.$router.push(`/c/${company}/schedule/company`)
          } else {
            this.$router.push('/dashboard')
          }
        })
      }).catch((err) => {
        const { password, ...data } = this.registrationData

        if (err.response.status === 400 && err.response.data.errors) {
          this.showErrorAlert = true
          this.alertText = err.response.data.errors
        } else {
          this.toastError(this.$t('Invalid credentials'))
        }
        this.$store.dispatch('setLog', {
          data: {
            userData: data,
            errors: err.response.data ? err.response.data.errors : []
          }
        }).catch(() => {})
        this.clicked = false
      })
    },
    getCookie (name) {
      // eslint-disable-next-line no-useless-escape
      const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'))
      const data = {}
      if (matches) {
        const decodeUri = decodeURIComponent(matches[1])
        const decodeData = decodeUri.split('|||')

        decodeData.forEach(elem => {
          const after = elem.slice(elem.indexOf('=') + 1)
          const before = elem.slice(0, elem.indexOf('='))
          data[before] = after
        })
      }

      return data
    },
    parsePhoneNumber (number) {
      number = number.substr(1)
      number = number.replace(/\s/g, '')
      return number
    },
    checkEmail () {
      if (this.registrationData.email) {
        this.$store.dispatch('checkEmail', { email: this.registrationData.email })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
