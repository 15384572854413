<template>
  <div>
    <template v-if="profile">
      <template v-if="profile.is_email_exist || profile.is_phone_exist">
        <div class="d-flex justify-content-center">
          <div class="card col-12 col-md-5">
            <div class="card-header">
              <h6 class="text-center">{{ $t('You have received an invitation from') }} {{ registrationData.company_name }}</h6>
              <div class="text-center">{{ $t('Enter your account password to become its employee') }}</div>
              <div class="text-center mt-2">
                <div>{{$t('MOBILE_APP_TITLE')}}</div>
                <a v-if="showGoogle" href="https://play.google.com/store/apps/details?id=com.shifton.work" target="_blank">
                  <img style="width: 150px; vertical-align: middle" src="@/assets/images/google-play-badge.png" alt="">
                </a>
                <a v-if="showIOS" href="https://apps.apple.com/app/shifton/id1510772762" target="_blank">
                  <img style="width: 120px; vertical-align: middle" src="@/assets/images/App_Store.svg" alt="">
                </a>
              </div>
            </div>
            <div class="card-body justify-content-center">
              <div>
                {{ $t('Your login') }}: {{ username }}
              </div>
              <Form ref="phoneExist">
                <ui-text-input
                  class="mt-3"
                  id="password"
                  name="password"
                  type="password"
                  v-model="password"
                  :validation="{required: true, password: true, min: 8}"
                  data-cy="password-input"
                  :placeholder="$t('Password')"
                  prepend-icon="icon-lock"
                />
              </Form>
              <small>
                <router-link v-on:click="hideBadge()" :to="`/password/recovery?lang=${lang}`" >{{ $t('Forgot password?') }}</router-link>
              </small>
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  name="submit"
                  @click="login"
                  class="btn btn-primary rounded-pill shadow">
                  {{ $t('Sign in') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-center">
          <div class="card col-12 col-md-5">
            <div class="card-header">
              <h6 class="text-center">{{ $t('You have received an invitation from') }} {{ registrationData.company_name }}</h6>
              <div class="text-center">{{ $t('Enter your data to continue working within Shifton service') }}</div>
              <div class="text-center mt-2">
                <div>{{$t('MOBILE_APP_TITLE')}}</div>
                <a v-if="showGoogle" href="https://play.google.com/store/apps/details?id=com.shifton.work" target="_blank">
                  <img style="width: 150px; vertical-align: middle" src="@/assets/images/google-play-badge.png" alt="">
                </a>
                <a v-if="showIOS" href="https://apps.apple.com/app/shifton/id1510772762" target="_blank">
                  <img style="width: 120px; vertical-align: middle" src="@/assets/images/App_Store.svg" alt="">
                </a>
              </div>
            </div>
            <div class="card-body justify-content-center">
              <div v-if="displayConfig && (displayConfig.includes('microsoft') || displayConfig.includes('google'))" class="pt-4 d-flex justify-content-center">
                <div class="row d-flex justify-content-center">
                  <div class="col-12 d-flex justify-content-center pb-2">
                    {{ $t('Register with') }}
                  </div>
                  <div v-if="displayConfig.includes('google')" class="col-12 d-flex justify-content-center">
                    <router-link @click="authenticate('google')" to="">
                      <img src="@/assets/images/g_sign_in.png" width="180" alt="shifton">
                    </router-link>
                  </div>
                  <div v-if="displayConfig.includes('microsoft')" class="col-12 d-flex justify-content-center pt-1">
                    <router-link @click="authenticate('live')" to="">
                      <img src="@/assets/images/ms-live_signin_light.png" width="175" alt="shifton">
                    </router-link>
                  </div>
                  <div v-if="displayConfig.includes('form')" class="col-12 d-flex justify-content-center pt-2">
                    {{ $t('Or with form') }}
                  </div>
                </div>
              </div>
              <Form ref="observeForm" as="div">
                <div v-if="!displayConfig || displayConfig.includes('form')">
                  <div class="form-group">
                    <ui-text-input
                      id="registerInputFirstName"
                      :name="'first_name'"
                      v-model="registrationData.first_name"
                      :validation="{required: true, max: 128}"
                      :label="$t('First name')"
                      :placeholder="$t('First name')"
                    />
                  </div>
                  <div class="form-group">
                    <ui-text-input
                      id="registerInputLastName"
                      :name="'last_name'"
                      v-model="registrationData.last_name"
                      :validation="{required: true, max: 128}"
                      :label="$t('Last name')"
                      :placeholder="$t('Last name')"
                    />
                  </div>
                  <div class="form-group">
                    <ui-text-input
                      id="registerInputEmail"
                      :name="'email'"
                      v-model="registrationData.email.value"
                      :validation="{email: true}"
                      :label="$t('E-mail')"
                      :disabled="registrationData.email.disable"
                      :placeholder="$t('Enter your e-mail')"
                    />
                  </div>
                  <div class="form-group">
                    <ui-tel-input
                      name="phone"
                      :label="$t('Phone number')"
                      id="registerInputPhone"
                      v-model="registrationData.number.value"
                      @input="validateInput"
                      :disabled="registrationData.number.disable"
                      :validate="{max:20}">
                    </ui-tel-input>
                  </div>
                  <div class="form-group">
                    <ui-select
                      name="time_zone"
                      v-model="registrationData.time_zone"
                      class="w-100"
                      :options="allZones"
                      :key-name="'id'"
                      :label="$t('Time zone')"
                      :value-name="'id'"
                    />
                  </div>
                  <div class="form-group">
                    <ui-text-input
                      id="registerInputPassword"
                      :type="'password'"
                      :name="'password'"
                      v-model="registrationData.password"
                      :validation="{required: true, password: true, min: 8}"
                      :label="$t('Password')"
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      name="submit"
                      @click="registerUser"
                      class="btn btn-success rounded-pill shadow">
                      {{ $t('Sign up') }}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card">
        <div class="card-body">
          <div class="my-5 text-center font-weight-bold text-uppercase" v-html="error"></div>
          <div class="text-center font-weight-bold text-uppercase mb-5">
            <button
              class="btn btn-success rounded-pill"
              @click="goLogin">
              {{ $t('Sign In') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import errorMixin from '../mixins/mixinApiErrors'
import analyticsMixin from '@/mixins/analyticsMixin'
import { loadLanguageAsync } from '@/i18n'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'

export default {
  name: 'ConfirmCompanyInvite',
  mixins: [errorMixin, analyticsMixin, mixinWindowWidthWatch],

  data () {
    return {
      hash: null,
      action: null,
      profile: null,
      password: null,
      lang: 'en',
      languages: ['ru', 'en', 'he', 'uk', 'de', 'pl', 'es', 'el'],
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      registrationData: {
        time_zone: null,
        account_type: 'employee',
        first_name: '',
        last_name: '',
        locale: 'en',
        company_name: '',
        email: {
          value: null,
          disable: false
        },
        password: '',
        number: {
          value: '',
          disabled: false,
          valid: true
        },
        recaptcha_key: null
      },
      invitedCompanyId: null,
      captchaSize: 'normal',
      error: null,
      phoneObject: null,
      accessToken: null,
      displayConfig: null
    }
  },
  beforeCreate () {
    if (localStorage.getItem('api_key')) {
      localStorage.clear()
      location.reload()
    }
  },
  beforeMount () {
    if (this.isMobile) this.captchaSize = 'compact'
    this.hash = this.$route.params.hash
    this.action = this.$route.params.action
    if (this.action === 'accept') {
      this.setLanguage()
      this.$store.dispatch('getInvitedUser', this.hash).then(response => {
        this.profile = JSON.parse(JSON.stringify(response))
        this.invitedCompanyId = response.company_id
        this.displayConfig = response.register_form_config
        // console.log(this.displayConfig)
        this.confirmInvite()
      }).catch((e) => {
        this.error = this.$t(e.response.data.message)
        setTimeout(() => {
          this.$router.push('/login?lang=' + this.$route.query.lang)
        }, 5000)
      })
    } else if (this.action === 'decline') {
      this.declineInvite()
    } else {
      this.$router.push('/profile')
    }
  },
  computed: {
    username () {
      if (this.profile.is_email_exist) return this.profile.email
      if (this.profile.is_phone_exist) return this.profile.phone
      return null
    },
    showGoogle () {
      return this.getOS === 'Android' || this.getOS === 'Windows' || this.getOS === 'Linux' || this.getOS === 'MacOS'
    },
    showIOS () {
      return this.getOS === 'MacOS' || this.getOS === 'iOS' || this.getOS === 'Windows' || this.getOS === 'Linux'
    },
    ...mapGetters(['allZones'])
  },
  created () {
    this.$store.dispatch('getTimeZones')
    this.$store.dispatch('getTimeZone').then(response => {
      this.registrationData.time_zone = response
    }).catch(() => {
      this.registrationData.time_zone = moment.tz.guess()
    })
    if (this.languages.includes(this.$route.query.lang)) {
      this.lang = this.$route.query.lang
    }
  },
  watch: {
    $route (to, from) {
      if (this.languages.includes(this.$route.query.lang)) {
        this.lang = this.$route.query.lang
      }
    }
  },
  methods: {
    authenticate: function (provider) {
      if (this.$auth.isAuthenticated()) {
        this.$auth.logout()
      }
      this.response = null
      this.$auth.authenticate(provider).then(async (r) => {
        this.accessToken = this.$auth.getToken()
        await this.registerUser(provider)
      })
    },
    goLogin () {
      this.$router.push('/login?lang=' + this.$route.query.lang)
    },
    hideBadge () {
      this.$recaptchaInstance.hideBadge()
    },
    async getRecaptcha (action) {
      await this.$recaptchaLoaded()

      if (!this.recaptchaShow) {
        this.recaptcha_key = this.sitekey
      } else {
        this.recaptcha_key = await this.$recaptcha(action)
      }
    },
    confirmInvite () {
      this.$store.dispatch('confirmInviteAuth', this.hash).then((response) => {
        this.toastSuccess(this.$i18n?.t('You were successfully added to a company'))

        this.$store.dispatch('dropProfile').then(() => {
          this.$store.dispatch('getProfile').then(() => {
            this.$store.dispatch('getUserInfo').then(() => {
              this.$store.dispatch('clearCompanyStore').then(() => {
                this.$store.dispatch('getCompany', this.invitedCompanyId).then((company) => {
                  this.$router.push(`/c/${company.id}/dashboard`)
                })
              })
            })
          })
        })
      }).catch(() => {
        localStorage.clear()
        this.registrationData.first_name = this.profile.first_name
        this.registrationData.last_name = this.profile.last_name
        this.registrationData.company_name = this.profile.company_name
        if (this.profile.phone) {
          this.registrationData.number.value = '+' + this.profile.phone
          this.registrationData.number.disable = true
          this.registrationData.number.valid = true
          // this.bindPhoneProps.disabled = true
        }
        if (this.profile.email) {
          this.registrationData.email.value = this.profile.email
          this.registrationData.email.disable = true
          // this.bindPhoneProps.disabled = false // т.к. при наличии мыла инвайты всегда уходят на него, разблокируем инпут телефона для ввода
        }
      })
    },
    declineInvite () {
      this.$store.dispatch('declineInviteAuth', this.hash).then((response) => {
        this.trackEvent({
          gtm: {
            event: 'invite_decline',
            category: 'Invite',
            action: 'click'
          },
          crisp: {
            eventName: 'invite:decline'
          }
        })
        this.toastError(this.$i18n?.t('Invite has been declined.'))
        this.$router.push('/profile')
      })
    },
    login () {
      this.$refs.phoneExist.validate().then(async result => {
        if (result.valid) {
          await this.getRecaptcha('companyInviteLogin')
          this.$store.dispatch('getAuth', {
            username: this.username,
            password: this.password,
            grant_type: 'password',
            recaptcha_key: this.recaptcha_key
          }).then(() => {
            localStorage.setItem('unconfirmedProceed', 'true')
            this.setLanguage()
            this.confirmInvite()
          }).catch(() => {
          })
        }
      })
    },
    registerUser (provider = null) {
      this.$refs.observeForm.validate().then(async isValid => {
        if (isValid.valid || this.accessToken) {
          await this.getRecaptcha('ConfirmCompanyInvite')
          this.registrationData.recaptcha_key = this.recaptcha_key

          const locale = this.$route.query.lang ? this.$route.query.lang : 'en'
          const registrationData = JSON.parse(JSON.stringify(this.registrationData))
          delete registrationData.number
          if (this.registrationData.number.value) {
            if (!this.phoneObject || (this.phoneObject && this.phoneObject.number !== '+' + this.phoneObject.country.dialCode)) { // игнорим телефон, если введен только диалкод
              registrationData.phone = this.parsePhoneNumber(this.registrationData.number.value)
            }
          }
          delete registrationData.email
          if (this.registrationData.email.value) {
            registrationData.email = this.registrationData.email.value
          }
          registrationData.hash = this.hash
          registrationData.time_zone = this.registrationData.time_zone
          registrationData.locale = locale
          registrationData.access_token = this.accessToken
          if (this.accessToken) {
            registrationData.provider = provider
            delete registrationData.password
          }
          if (this.accessToken) {
            this.handleRegister(registrationData)
          } else {
            this.$refs.observeForm.validate().then(result => {
              if (result && this.registrationData.recaptcha_key && this.registrationData.number.valid) {
                this.handleRegister(registrationData)
              } else if (!this.registrationData.number.valid) {
                this.errors.add({
                  field: 'phone',
                  msg: 'Wrong number'
                })
              }
            })
          }
        }
      })
    },
    handleRegister (registrationData) {
      this.$store.dispatch('register', registrationData).then((response) => {
        this.trackEvent({
          gtm: {
            event: 'invite_accepted',
            category: 'Invite',
            action: 'click'
          },
          crisp: {
            eventName: 'employee:register'
          }
        })
        if (response) {
          localStorage.setItem('unconfirmedProceed', 'true')
          this.setLanguage()
          this.confirmInvite()
        }
      }).catch((err) => {
        const { password, ...data } = registrationData
        if (this.accessToken && err.response.data && err.response.data.errors && typeof err.response.data.errors === 'object') {
          Object.keys(err.response.data.errors).forEach(key => {
            this.toastError(this.$i18n?.t(err.response.data.errors[key]))
          })
        }
        this.accessToken = null
        this.$store.dispatch('setLog', {
          data: {
            userData: data,
            errors: err.response.data ? err.response.data.errors : []
          }
        })
        this.$recaptchaInstance.hideBadge()
      })
    },
    setLanguage () {
      const locale = this.$route.query.lang ? this.$route.query.lang : this.$store.getters.locale ? this.$store.getters.locale : 'en'
      loadLanguageAsync(locale)
    },
    checkEmail () {
      if (this.registrationData.email) {
        this.$store.dispatch('checkEmail', { email: this.registrationData.email })
      }
    },
    parsePhoneNumber (number) {
      number = number.substr(1)
      number = number.replace(/\s/g, '')
      return number
    },
    validateInput (number, phone) {
      this.phoneObject = phone
      if (!number) {
        this.registrationData.number.valid = true
        this.errors.remove('number')
        return
      }
      if (number === '+' + phone.country.dialCode) {
        this.registrationData.number.valid = true
        this.errors.remove('number')
        return
      }
      if (!phone.isValid) {
        this.errors.add({
          field: 'number',
          msg: this.$t('Wrong number')
        })
        this.registrationData.number.valid = false
      } else {
        this.registrationData.number.valid = true
        this.errors.remove('number')
      }
    }
  }
}
</script>
<style lang="scss">
</style>
