<template>
  <el-dialog
    key="showPersonalizationModal"
    :title="$t('PERSONALIZATION_MODAL_TITLE')"
    :close-on-click-modal="true"
    :show-close="true"
    v-model="showPersonalizationModal"
    :before-close="handleClose"
    width="40%">
    <div style="max-height: 300px; overflow:auto; background-color: white;">
      <div class="text-wrap text-break">
        {{ $t('PERSONALIZATION_MODAL_INFORMATION') }}
      </div>
      <div class="my-2">
        <ui-switch
          v-model="impersonalizationAccess"
          @change="val => impersonalizationAccess = val"
          :active-text="$t('Enable personalization')"
        />
      </div>
      <div class="text-wrap text-break">
        {{ $t('PERSONALIZATION_MODAL_SUBINFORMATION') }}
      </div>
    </div>
    <template #footer>
        <span class="dialog-footer">
        <el-button type="primary" @click="saveImpersonalization">{{ $t('Confirm') }}</el-button>
      </span>
    </template>
  </el-dialog>
  <li class="nav-item dropdown no-md-border my-auto">
    <div class="head-link nav-link px-0"
         style="cursor: pointer"
         id="helpDropdown"
         role="button"
         :class="{
           'border-top': isMobile
         }"
         @click="trackHelpEvents()"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false">
              <span class="header-icon text-center d-none d-md-block text-primary" style="font-size: 30px;">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="$t('HELP_TOOLTIP')"
                  placement="top"
                  :show-after="1000">
                   <i class="icon-help-circle"/>
                </el-tooltip>
              </span>
              <span class="d-md-none p-0 mx-2">
                <i class="icon-help-circle"></i>
              </span>
              <span class="d-md-none menu-color" :class="{ mobileItemStyle: isMobile }">{{ $t('Help') }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="helpDropdown">
      <a class="dropdown-item" :href="helpLink" target="_blank" @click="trackHelpEvents('link', 'Help articles')">
        {{ $t('Help articles') }}
      </a>
      <a class="dropdown-item" :href="socialLinks.youtube" target="_blank" @click="trackHelpEvents('link', 'Video tutorials')">
        {{ $t('Video tutorials') }}
      </a>
      <button class="dropdown-item" @click="openImpersonalization">
        {{ $t('Impersonalization') }}
      </button>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" :href="socialLinks.facebook" target="_blank" @click="trackHelpEvents('link', 'Facebook')">
        <i class="icon-facebook"></i> {{ $t('Facebook') }}
      </a>
      <a class="dropdown-item" :href="socialLinks.twitter" target="_blank" @click="trackHelpEvents('link', 'Twitter')">
        <i class="icon-twitter"></i> {{ $t('Twitter') }}
      </a>
      <a class="dropdown-item" :href="socialLinks.linkedin" target="_blank" @click="trackHelpEvents('link', 'Linkedin')">
        <i class="icon-linkedin"></i> {{ $t('Linkedin') }}
      </a>
    </div>
  </li>
</template>
<script>
import analyticsMixin from '@/mixins/analyticsMixin'

export default {
  name: 'HeaderHelpComponent',
  mixins: [analyticsMixin],
  data () {
    return {
      socialLinks: {
        twitter: 'https://twitter.com/shifton_com/',
        linkedin: 'https://www.linkedin.com/company/65830746/',
        facebook: 'https://www.facebook.com/shifton.en/',
        youtube: 'https://www.youtube.com/channel/UCzwHqjHVI-e9xLpWJRUmRHw'
      },
      profile: null,
      impersonalizationAccess: false,
      showPersonalizationModal: false
    }
  },
  props: {
    isMobile: Boolean
  },
  mounted () {
    this.setSocialLink()
  },
  computed: {
    locale () {
      return this.$store.getters.locale
    },
    linkLang () {
      return this.locale === 'ru' ? 'ru' : 'en'
    },
    helpLink () {
      return `https://help.shifton.com/${this.linkLang}/home`
    }
  },
  watch: {
    locale () {
      this.setSocialLink()
    }
  },
  methods: {
    trackHelpEvents (action = 'opened', description = '') {
      const crispEventData = description
        ? { action_description: description } // We add data only if there is a description
        : undefined // If there is no description, the property is not added.

      this.trackEvent({
        gtm: {
          event: 'help_' + action,
          category: 'Navigation',
          action: 'click',
          ...(description && { label: description }) // Add to gtm only if there is a description
        },
        crisp: {
          eventName: 'help:' + action,
          ...(crispEventData && { eventData: crispEventData }) // Add eventData only if there is data
        }
      })
    },
    setSocialLink () {
      switch (this.locale) {
        case 'ru':
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.ru/'
          this.socialLinks.youtube = 'https://www.youtube.com/channel/UCzwHqjHVI-e9xLpWJRUmRHw'
          break
        case 'uk':
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.ua/'
          this.socialLinks.youtube = 'https://www.youtube.com/@shifton-ua5029'
          break
        default:
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.en/'
          this.socialLinks.youtube = 'https://www.youtube.com/@shifton-eng8848'
      }
    },
    openImpersonalization () {
      this.trackHelpEvents('opened_impersonalization')
      this.$store.dispatch('dropProfile').then(() => {
        this.$store.dispatch('getProfile').then(profile => {
          this.profile = profile
          this.impersonalizationAccess = profile.impersonalization_access
        })
      })
      this.showPersonalizationModal = true
    },
    handleClose () {
      this.showPersonalizationModal = false
    },
    saveImpersonalization () {
      if (this.profile.impersonalization_access !== this.impersonalizationAccess) {
        const data = {
          impersonalization_access: this.impersonalizationAccess
        }
        this.$store.dispatch('setProfile', data).then((response) => {
          this.profile = response
          this.impersonalizationAccess = response.impersonalization_access
          this.toastSuccess(this.$i18n?.t('Profile successfully saved'))
        }).catch(e => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }

      this.showPersonalizationModal = false
    }
  }
}
</script>
<style scoped lang="scss">
.mobileItemStyle {
  padding-left: 1.25rem;
}
</style>
