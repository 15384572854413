<template>
  <div class="row align-items-center bg-light py-1 my-1" v-if="attendance && show">
    <template v-for="(event) in attendance.next_events">
      <template v-if="event.type === 'shift_start' || event.type === 'shift_end'">
        <template v-for="shift in event.data" :key="(shift.id ? shift.id : shift.schedule_id) + '__sh'">
          <div class="col-auto text-left card mx-1">
            <div class="row align-items-center">
              <div class="col my-1">
                <div v-if="timing['sh_' + shift.schedule_id + shift.id]" :class="shiftTimerClass['sh_' + shift.schedule_id + shift.id]">
                  {{timing['sh_' + shift.schedule_id + shift.id]}}
                </div>
                <div>
                  <i role="button" class="icon-calendar"></i> {{ getScheduleName(shift.schedule_id) }}
                </div>
                <div v-if="shift.template">
                  <i role="button" class="icon-eye"></i> {{ shift.template ? shift.template.name : null }}
                </div>
                <div>
                  {{toTimeZone(shift.time_from).format(localeTimeFormat)}} -
                  {{toTimeZone(shift.time_to).format(localeTimeFormat)}}
                </div>
              </div>
              <div class="col-auto align-content-end">
                <button
                  type="submit"
                  @click="proceedAttendance(event.type, shift.id, shift.schedule_id)"
                  data-cy="switch-view"
                  :class="event.type === 'shift_start' ? 'btn-outline-success' : 'btn-outline-danger'"
                  class="btn btn-sm rounded-pill shadow-sm m-1">
                  <template v-if="event.type === 'shift_end'">
                    {{ $t('END_SHIFT_ATTENDANCE') }}
                  </template>
                  <template v-if="event.type === 'shift_start'">
                    {{ $t('START_SHIFT_ATTENDANCE') }}
                  </template>
                </button>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-if="event.type === 'break_start' || event.type === 'break_end'">
        <template v-for="breakEvent in event.data" :key="breakEvent.id + '__br'">
          <div class="col-auto text-left card mx-1">
            <div class="row align-items-center">
              <div class="col my-1">
                <div :class="breakTimerClass['br_' + breakEvent.id]">
                  {{timing['br_' + breakEvent.id]}}
                </div>
                <div>
                  {{ $t(breakEvent.title ? breakEvent.title : 'CUSTOM_BREAK_TITLE')}}
                </div>
                <div>
                  {{toTimeZone(breakEvent.time_from).format(localeTimeFormat)}} -
                  {{toTimeZone(breakEvent.time_to).format(localeTimeFormat)}}
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="submit"
                  @click="proceedAttendance(event.type, breakEvent.id)"
                  data-cy="switch-view"
                  class="btn btn-sm btn-outline-primary rounded-pill shadow-sm m-1">
                  <template v-if="event.type === 'break_end'">
                    {{ $t('END_BREAK_ATTENDANCE') }}
                  </template>
                  <template v-if="event.type === 'break_start'">
                    {{ $t('START_BREAK_ATTENDANCE') }}
                  </template>
                </button>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import moment from 'moment'

export default {
  name: 'AttendanceDashboardComponent',
  mixins: [momentMixin, mixinWindowWidthWatch],
  data () {
    return {
      attendance: null,
      modalType: null,
      object_id: null,
      timing: {},
      intervals: [],
      getInterval: null,
      shiftTimerClass: {},
      breakTimerClass: {}
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$eventBus.on('getAttendance', this.init)
  },
  beforeUnmount () {
    this.intervals.forEach(intervalId => {
      clearInterval(intervalId)
    })
    clearInterval(this.getInterval)
    this.getInterval = null
    this.intervals = []
  },
  computed: {
    ...mapGetters({
      schedules: 'schedules'
    }),
    show () {
      if (!this.attendance) {
        return false
      }
      let value = false
      this.attendance.next_events.forEach(next => {
        if (next.data.length > 0) {
          value = true
        }
      })
      return value
    }
  },
  methods: {
    init () {
      clearInterval(this.getInterval)
      this.getInterval = null
      this.intervals.forEach(intervalId => {
        clearInterval(intervalId)
      })
      this.intervals = []
      this.getAttendance()
      const intId = setInterval(() => {
        this.getAttendance()
      }, 1000 * 60 * 0.5)
      this.getInterval = intId
    },
    shiftStartTitle (shift) {
      if (moment().isAfter(moment(this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone)))) {
        const duration = moment.utc(moment()
          .diff(moment(this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone))))
          .format('HH:mm')
        // console.log('shift', shift)
        if (shift.id) {
          this.timing['sh_' + shift.schedule_id + shift.id] = this.$t('LATE_ON_SHIFT') + ' ' + duration
          this.shiftTimerClass['sh_' + shift.schedule_id + shift.id] = 'text-danger'
        }
      } else {
        if (!shift.planned_from) {
          this.timing['sh_' + shift.schedule_id + shift.id] = this.$t('CUSTOM_SHIFT_START_TITLE')
        } else {
          const duration = moment.utc(this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone)
            .diff(moment()))
            .format('HH:mm')
          this.timing['sh_' + shift.schedule_id + shift.id] = this.$t('NEXT_SHIFT_ON') + ' ' + duration
          this.shiftTimerClass['sh_' + shift.schedule_id + shift.id] = 'text-primary'
        }
      }
    },
    shiftEndTitle (shift) {
      const duration = moment.utc(moment()
        .diff(this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone)))
        .format('HH:mm')
      this.timing['sh_' + shift.schedule_id + shift.id] = this.$t('TIME_ON_SHIFT') + ' ' + duration
      this.shiftTimerClass['sh_' + shift.schedule_id + shift.id] = 'text-success'
    },
    breakStartTitle (breakEvent) {
      if (moment().isAfter(moment(this.fromZoneToZone(breakEvent.time_from, 'UTC', this.$store.getters.profileTimeZone)))) {
        const duration = moment.utc(moment()
          .diff(this.fromZoneToZone(breakEvent.time_from, 'UTC', this.$store.getters.profileTimeZone)))
          .format('HH:mm')
        this.timing['br_' + breakEvent.id] = this.$t('LATE_ON_BREAK') + ' ' + duration
        this.breakTimerClass['br_' + breakEvent.id] = 'text-danger'
      } else {
        if (!breakEvent.planned_from) {
          this.timing['br_' + breakEvent.id] = this.$t('CUSTOM_BREAK_START_TITLE')
        } else {
          const duration = moment.utc(moment(this.fromZoneToZone(breakEvent.time_from, 'UTC', this.$store.getters.profileTimeZone)).diff(moment())).format('HH:mm')
          this.timing['br_' + breakEvent.id] = this.$t('NEXT_BREAK_ON') + ' ' + duration
        }
        this.breakTimerClass['br_' + breakEvent.id] = 'text-primary'
      }
    },
    breakEndTitle (breakEvent) {
      if (moment().isAfter(moment(this.fromZoneToZone(breakEvent.time_to, 'UTC', this.$store.getters.profileTimeZone)))) {
        const duration = moment.utc(moment()
          .diff(this.fromZoneToZone(breakEvent.time_to, 'UTC', this.$store.getters.profileTimeZone)))
          .format('HH:mm')
        this.timing['br_' + breakEvent.id] = this.$t('AFTER_BREAK_END') + ' ' + duration
        this.breakTimerClass['br_' + breakEvent.id] = 'text-danger'
      } else {
        const duration = moment.utc(moment(this.fromZoneToZone(breakEvent.time_to, 'UTC', this.$store.getters.profileTimeZone))
          .diff(moment()))
          .format('HH:mm')
        this.timing['br_' + breakEvent.id] = this.$t('BEFORE_BREAK_END') + ' ' + duration
        this.breakTimerClass['br_' + breakEvent.id] = 'text-success'
      }
    },
    proceedAttendance (type, objectId = null, scheduleId = null) {
      const payload = {
        type: type
      }

      if (objectId) {
        payload.object_id = objectId
      }

      if (scheduleId) {
        payload.schedule_id = scheduleId
      }

      // console.log(type, objectId)
      this.$store.dispatch('updateAttendance', payload).then(response => {
        this.$eventBus.emit('updateActivityStatus')
        this.init()
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    },
    getAttendance () {
      this.$store.dispatch('getAttendance').then(response => {
        this.attendance = response
        this.attendance.next_events.forEach(event => {
          if (event.type === 'shift_end') {
            event.data.forEach(shift => {
              this.shiftEndTitle(shift)
              const intervalId = setInterval(() => {
                this.shiftEndTitle(shift)
              }, 1000)
              this.intervals.push(intervalId)
            })
          }
          if (event.type === 'shift_start') {
            event.data.forEach(shift => {
              this.shiftStartTitle(shift)
              const intervalId = setInterval(() => {
                this.shiftStartTitle(shift)
              }, 1000 * 60)
              this.intervals.push(intervalId)
            })
          }
          if (event.type === 'break_end') {
            event.data.forEach(breakEvent => {
              this.breakEndTitle(breakEvent)
              const intervalId = setInterval(() => {
                this.breakEndTitle(breakEvent)
              }, 1000 * 60)
              this.intervals.push(intervalId)
            })
          }
          if (event.type === 'break_start') {
            event.data.forEach(breakEvent => {
              this.breakStartTitle(breakEvent)
              const intervalId = setInterval(() => {
                this.breakStartTitle(breakEvent)
              }, 1000 * 60)
              this.intervals.push(intervalId)
            })
          }
        })
      }).catch(() => {})
    },
    getScheduleName (scheduleId) {
      const schedule = this.$store.getters.scheduleById(scheduleId)
      if (schedule) {
        return schedule.name
      }
      return ''
    }
  }
}
</script>
