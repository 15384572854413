import { Crisp } from 'crisp-sdk-web'

export default {
  install (app, options) {
    if (!options || !options.websiteId) {
      console.error('Crisp: Website ID not specified!')
      return
    }

    if (process.env.NODE_ENV === 'production') {
      // Initialization Crisp
      Crisp.configure(options.websiteId)

      // Add Crisp as a global state
      app.config.globalProperties.$crisp = Crisp

      console.log('Crisp has been successfully connected!')
    }
  }
}
