<template>
  <div></div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import analyticsMixin from '@/mixins/analyticsMixin'
import mixinColors from '@/mixins/mixinColors'
import { scheduleDataTemplate } from './data-schedule'
import { toRaw } from 'vue'

export default {
  name: 'SaveStep',
  mixins: [momentMixin, mixinColors, analyticsMixin],
  props: {
    selectedPositions: {
      type: Array,
      required: true
    },
    employees: {
      type: Array,
      required: true
    },
    shiftTemplates: {
      type: Array,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    scheduleTemplate: {
      type: String,
      required: true
    },
    createShifts: {},
    selectedModules: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      scheduleDataTemplate: scheduleDataTemplate,
      createdEmployeesIds: [],
      createdPositions: {},
      shiftTemplate: {
        id: null,
        name: '',
        break_time: 60,
        color: '#9762bf',
        by_days: false,
        night_shift: false,
        allow_break: false,
        workplacesTotal: 1,
        workplaces: [1, 1, 1, 1, 1, 1, 1],
        breaks: [],
        users: [],
        time_from: '09:00',
        time_to: '18:00',
        marks: []
      },
      template: {
        id: null,
        name: '',
        time_from: '09:00',
        time_to: '18:00',
        break_time: 60,
        color: '#4e93e9',
        by_days: false,
        night_shift: false,
        allow_break: false,
        users: [],
        workplacesTotal: 1,
        breaks: [],
        workplaces: [
          1, 1, 1, 1, 1, 1, 1
        ]
      },
      createdTemplates: [],
      shiftData: {
        schedule_id: null,
        template_id: null,
        user_ids: [null],
        date_from: '2022-09-26',
        date_to: '2022-10-02',
        break_time: 60,
        marks: [],
        shifts_number: 1,
        location_id: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: ['2022-09-28'],
        type: 'period'
      },
      dateFrom: moment().startOf('week'),
      dateTo: moment().endOf('week')
    }
  },
  watch: {
    createShifts () {
      if (this.createShifts) {
        this.generateSchedule()
      } else {
        this.saveWithoutShifts()
      }
    },
    generationInfo () {
      if (this.generationInfo?.status === 'generation_processed') {
        this.finishGenerateSchedule()
      } else {
        this.$emit('disableLoader')
        this.displayErrorMessage(new Error(this.$i18n?.t('Generation failed')))
      }
    }
  },
  computed: {
    projectId () {
      return this.$store.getters.currentProjectId
    },
    scheduleId () {
      return this.$store.getters.schedulesByProject[0]
    },
    ownerId () {
      return this.$store.getters.company.employee_id
    },
    generationInfo () {
      return this.$store.getters.generationInfo
    }
  },
  methods: {
    async saveWithoutShifts () {
      await this.saveModules()
      await this.save()
    },
    async generateSchedule () {
      this.selectedPositions.length > 0 && await this.createPositions()
      await this.createEmployees()

      const employeesIds = [...this.createdEmployeesIds, this.ownerId]
      const shiftTemplates = this.makeScheduleShiftTemplates()

      const scheduleData = {
        ...this.scheduleDataTemplate,
        name: this.$i18n?.t('My first schedule'),
        pattern_type: this.scheduleTemplate,
        date_from: moment().startOf('month').subtract(7, 'days').format(this.backendDateTimeFormat),
        date_to: moment().add(1, 'month').add(1, 'days').format(this.backendDateTimeFormat),
        company_id: this.companyId,
        project_id: this.projectId,
        users: employeesIds,
        shifts_templates: shiftTemplates
      }

      await this.createSchedule(scheduleData)

      this.selectedPositions.length > 0 && await this.attachPositions()

      await this.saveModules()
    },
    async finishGenerateSchedule () {
      try {
        this.scheduleId && await this.deleteFirstEmptySchedule()
        await this.$eventBus.emit('reloadPage')
        await this.$eventBus.emit('refetchEvents')
        this.displaySuccessMessage()
        this.$emit('closeModal')
      } catch (error) {
        this.displayErrorMessage(error)
      } finally {
        this.$emit('disableLoader')
      }
    },
    makeScheduleShiftTemplates () {
      const scheduleShiftTemplates = this.shiftTemplates
        .filter(({ title, startTime, endTime }) => title && startTime && endTime)
        .map(({ title, startTime, endTime }) => {
          return {
            ...this.shiftTemplate,
            name: title,
            time_from: startTime,
            time_to: endTime,
            color: this.colorsChoices[Math.floor(Math.random() * this.colorsChoices.length)]
          }
        })

      return scheduleShiftTemplates
    },
    async createSchedule (scheduleData) {
      await this.$store.dispatch('addSchedule', scheduleData)
    },
    async deleteFirstEmptySchedule () {
      await this.$store.dispatch('deleteSchedule', this.scheduleId)
    },
    async save () {
      this.selectedPositions.length > 0 && await this.createPositions()
      await this.createShitTemplates()

      await this.createEmployees()
      this.createdEmployeesIds.length > 0 && await this.addEmployeesToSchedule()

      this.selectedPositions.length > 0 && this.attachPositions()
      this.createShifts && this.createShiftsByTemplates()

      this.$eventBus.emit('refetchEvents')
      this.displaySuccessMessage()
      this.$emit('closeModal')
      this.$emit('disableLoader')
    },
    async createPositions () {
      const results = await Promise.all(this.selectedPositions.map(async position => {
        const newPosition = {
          title: position,
          color: '#bbbbbb',
          priority: 1,
          monthly_hrs: null
        }

        return this.$store.dispatch('createPosition', [this.companyId, newPosition])
      }))

      results.forEach(result => {
        this.createdPositions = { ...this.createdPositions, [result.title]: result.id }
      })
    },
    async createEmployees () {
      const employeesToCreate = this.employees
        .filter((employee, index) => index !== 0 && employee.first_name && employee.last_name)
        .map(emp => {
          return {
            ...emp,
            project_id: this.projectId
          }
        })

      if (employeesToCreate.length === 0) return

      const response = await this.$store.dispatch('addEmployees', [this.companyId, employeesToCreate])

      const createdEmployeesArr = Object.values(response)
      createdEmployeesArr.pop()
      this.createdEmployeesIds = createdEmployeesArr.map(({ id }) => id)

      this.employees.forEach((employee, index) => {
        if (index !== 0 && employee.first_name) {
          employee.employee_id = createdEmployeesArr[index - 1].id
        }
      })
    },
    async addEmployeesToSchedule () {
      const formData = {
        users: this.createdEmployeesIds,
        add_type: 'without_shifts',
        date_from: moment(this.addDate).format(this.backendDateTimeFormat),
        preview: false
      }

      await this.$store.dispatch('addUsers', [this.scheduleId, formData])
    },
    async attachPositions () {
      const employeesToAttachPositions = this.employees.filter(employee => {
        if (!this.createdPositions[employee.position]) {
          return false
        }

        return true
      })
      await Promise.all(employeesToAttachPositions.map(employee => {
        const positionPayload = {
          positions_id: this.createdPositions[employee.position],
          date: this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
        }
        return this.$store.dispatch('attachPositions', [this.company.id, employee.employee_id, positionPayload])
      }))
    },
    async createShitTemplates () {
      await Promise.all(this.shiftTemplates
        .filter(({ title, startTime, endTime }) => title && startTime && endTime)
        .map(async ({ title, startTime, endTime }) => {
          const newTemplate = {
            ...this.template,
            name: title,
            time_from: startTime,
            time_to: endTime,
            color: this.colorsChoices[Math.floor(Math.random() * this.colorsChoices.length)]
          }
          const result = await this.$store.dispatch('saveTemplate', [this.scheduleId, newTemplate])
          this.createdTemplates.push(result)
        }))
    },
    createShiftsByTemplates () {
      const currentEmployees = this.employees
        .filter(employee => employee.first_name)
        .map((employee) => employee.employee_id)
      let i = 0

      currentEmployees.forEach((employee) => {
        const formData = {
          schedule_id: this.scheduleId,
          template_id: this.createdTemplates[i].id,
          user_ids: [employee],
          date_from: this.dateFrom.format(this.backendDateFormat),
          date_to: this.dateTo.format(this.backendDateFormat),
          break_time: 60,
          marks: [],
          shifts_number: 1,
          location_id: null,
          days: [0, 1, 2, 3, 4],
          dates: [moment().format(this.backendDateFormat)],
          type: 'period'
        }

        i++
        if (i >= this.createdTemplates.length) {
          i = 0
        }

        this.$store.dispatch('createShift', formData)
      })
    },
    async saveModules () {
      const company = toRaw(this.company)
      await this.$store.dispatch('setModulesByCompany', { modules: this.selectedModules })
      await this.$store.dispatch('getModulesByCompany')
      await this.$store.dispatch('editCompany', [this.companyId, { wizard_passed: true, work_mode: company.work_mode }])
      this.trackEvent({
        gtm: {
          event: 'wizard_finished',
          category: 'Wizard',
          action: 'click'
        },
        crisp: {
          eventName: 'wizard:finished'
        }
      })
    },
    displayErrorMessage (error) {
      this.toastError(this.$i18n?.t(error.message))
    },
    displaySuccessMessage () {
      this.toastSuccess(this.$i18n?.t('Quick setup completed'))
    }
  }
}
</script>

<style>

</style>
