import {
  GET_COMPANY,
  // GET_COMPANY_FIELDS,
  GET_COMPANY_LIST,
  GET_COMPANY_INVITED_USERS,
  GET_BILLING_ADDRESS,
  GET_MODULES_BY_COMPANY,
  CLEAR_COMPANY,
  PAYMENT_REQUIRED,
  SET_PROJECT_ROLE,
  PROJECT_LIMIT,
  COMPANY_PROJECT_LIMIT,
  EMPLOYEE_PERMISSIONS,
  SET_MODULES_BY_COMPANY
} from '../mutation-types.js'
import { HTTP } from '@/api/http_utils'

let promises = {}

const MODULES_IDS_BY_COMPANY = 'MODULES_IDS_BY_COMPANY'
const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING'
const UPDATE_WORK_MODE = 'UPDATE_WORK_MODE'

const state = {
  company: {},
  ModulesByCompany: [],
  companyId: null,
  workMode: 'work',
  currentCompanyUserId: null,
  companyOwnerId: null,
  projectsLimit: 0,
  // todo do refactor below
  companies: [],
  // Fields: {},
  // isOwner: (Number(localStorage.getItem('companyOwnerId')) === Number(this.$store.getters.profileId)),
  projectRole: localStorage.getItem('projectRole'),
  rolesByProjects: localStorage.getItem('rolesByProjects') || {},
  BillingAddress: {},
  projectsWhereUserIsManager: [],
  employeePermissions: null,
  companyModulesIds: [],
  isDataLoading: false
}

const getters = {
  company: state => state.company,
  workMode: state => state.workMode,
  basicRate: state => {
    return state.company.salary ? state.company.salary.basic_rate : null
  },
  companyId: state => state.companyId,
  companyOwnerId: state => state.companyOwnerId,
  currentCompanyUserId: state => state.currentCompanyUserId,
  companyRole: state => state.company.role,
  projectsLimit: state => state.projectsLimit,
  currency (state) {
    if (state.company && state.company.salary && state.company.salary.currency) {
      return state.company.salary.currency
    }
    return 'USD'
  },
  isOwner (state, getters, rootState) {
    if (!rootState) {
      return false
    }
    return +state.companyOwnerId === +rootState.profile.Profile.id
  },
  hasProject: state => state.company.first_project,
  // companyFields: state => state.Fields,
  // todo do refactor below
  ModulesByCompany: state => state.ModulesByCompany,
  companyModulesIds: state => state.companyModulesIds,
  companies: state => state.companies,
  projectRole: state => state.projectRole,
  rolesByProjects: state => state.rolesByProjects,
  doubleAccountAllowed: state => state.company.double_account === true,
  BillingAddress: state => state.BillingAddress,
  projectsWhereUserIsManager: state => state.projectsWhereUserIsManager,
  employeePermissions: state => state.employeePermissions,
  isDataLoading: state => state.isDataLoading
}

const mutations = {
  [UPDATE_IS_LOADING] (state, isLoading) {
    state.isDataLoading = isLoading
  },
  [CLEAR_COMPANY] (state) {
    state.company = {}
    // localStorage.removeItem('company')
  },
  [PAYMENT_REQUIRED] (state) {
    state.company.is_blocked = 1
    // const storeCompany = JSON.parse(localStorage.getItem('company'))
    // storeCompany.is_blocked = 1
    // localStorage.setItem('company', JSON.stringify(storeCompany))
  },
  [PROJECT_LIMIT] (state, limit) {
    state.projectsLimit = +limit
  },
  [COMPANY_PROJECT_LIMIT] (state, limit) {
    // console.log(limit)
    // const storeCompany = JSON.parse(localStorage.getItem('company'))
    // storeCompany.projects_limit = limit
    // localStorage.setItem('company', JSON.stringify(storeCompany))
    state.company.projects_limit = limit
  },
  [GET_COMPANY] (state, company) {
    state.company = company
    state.companyId = company.id
    state.currentCompanyUserId = company.employee_id
    state.companyOwnerId = company.owner_id
    state.workMode = company.stored_work_mode
    // localStorage.setItem('company', JSON.stringify(company))
  },
  // [GET_COMPANY_FIELDS] (state, fields) {
  //   state.Fields = fields
  // },
  [GET_COMPANY_LIST] (state, companies) {
    state.companies = companies
  },
  [GET_COMPANY_INVITED_USERS] (state, companyInvitedUsers) {
    state.companyInvitedUsers = companyInvitedUsers
  },
  [GET_BILLING_ADDRESS] (state, BillingAddress) {
    state.BillingAddress = BillingAddress
  },
  [GET_MODULES_BY_COMPANY] (state, modules) {
    state.ModulesByCompany = modules
  },
  [SET_MODULES_BY_COMPANY] (state, modules) {
    state.ModulesByCompany = modules
  },
  [MODULES_IDS_BY_COMPANY] (state, modules) {
    const companyModules = modules.map((module) => {
      const mod = []
      for (let i = 1; i <= module.count; i++) {
        mod.push(module.module_id)
      }
      return mod
    })
    state.companyModulesIds = [].concat(...companyModules)
  },
  [SET_PROJECT_ROLE] (state, projects) {
    const rolesByProjects = {}
    let perm = 'user'
    const projectsWhereUserIsManager = []

    projects.forEach(project => {
      if (project.role === 'manager') {
        projectsWhereUserIsManager.push(project)
        perm = 'manager'
        if (!localStorage.getItem('currentProject')) {
          localStorage.setItem('currentProject', project.id)
        }
      }
      rolesByProjects[project.id] = project.role
    })

    state.rolesByProjects = rolesByProjects
    localStorage.setItem('rolesByProjects', rolesByProjects)

    state.projectRole = perm
    localStorage.setItem('projectRole', perm)

    state.projectsWhereUserIsManager = projectsWhereUserIsManager
  },
  [EMPLOYEE_PERMISSIONS] (state, permissions) {
    state.employeePermissions = permissions
  },
  [UPDATE_WORK_MODE] (state, mode) {
    state.workMode = mode
  }
}

const actions = {
  updateIsLoading ({ commit }, isLoading) {
    commit(UPDATE_IS_LOADING, isLoading)
  },
  getCompany ({ commit }, id) {
    return new Promise((resolve, reject) => {
      if (state.company.id) {
        resolve(state.company)
      } else {
        HTTP.get(`/companies/${id}`).then(company => {
          const workMode = localStorage.getItem('work_mode')
          if (workMode) {
            company.stored_work_mode = workMode
          } else {
            company.stored_work_mode = company.work_mode
            localStorage.setItem('work_mode', company.work_mode)
          }
          commit(GET_COMPANY, company)
          commit(PROJECT_LIMIT, company.projects_limit)
          commit(EMPLOYEE_PERMISSIONS, company.employee_permissions)
          resolve(company)
        }).catch(err => {
          reject(err)
        })
      }
    })
  },
  createCompany ({ commit }, companyData) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies', companyData).then(company => {
        localStorage.setItem('work_mode', company.work_mode)
        company.stored_work_mode = company.work_mode
        commit(GET_COMPANY, company)
        commit(EMPLOYEE_PERMISSIONS, company.employee_permissions)
        resolve(company)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editCompany ({ commit }, [id, companyData]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + id, companyData).then(company => {
        localStorage.setItem('work_mode', company.work_mode)
        company.stored_work_mode = company.work_mode
        commit(GET_COMPANY, company)
        commit(EMPLOYEE_PERMISSIONS, company.employee_permissions)
        resolve(company)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editCompanyOnModulesPage ({ commit }, [id, companyData]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + id, companyData).then(company => {
        // localStorage.setItem('work_mode', company.work_mode)
        company.stored_work_mode = company.work_mode
        commit(GET_COMPANY, company)
        commit(EMPLOYEE_PERMISSIONS, company.employee_permissions)
        resolve(company)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateWorkMode ({ commit }, workMode) {
    commit(UPDATE_WORK_MODE, workMode)
  },
  changeOwner ({ commit }, [companyId, companyData]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/change-owner', companyData).then(company => {
        resolve(company)
      }).catch(err => {
        reject(err)
      })
    })
  },
  clearCompanyStore ({ commit }) {
    promises = {}
    commit(CLEAR_COMPANY)
  },
  paymentRequired ({ commit }) {
    commit(PAYMENT_REQUIRED)
  },
  updateProjectsLimit ({ commit }, projectLimit) {
    commit(COMPANY_PROJECT_LIMIT, projectLimit)
    commit(PROJECT_LIMIT, projectLimit)
  },
  setProjectRole ({ commit }, data) {
    commit(SET_PROJECT_ROLE, data)
  },
  getCompanyWorkingTime ({ commit, dispatch }, [id, from, to]) {
    const promiseKey = `/companies/${id}/working_time?from=${from}&to=${to}`

    if (promises[promiseKey]) {
      console.info('repeating requests getCompanyWorkingTime')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${id}/working_time?from=${from}&to=${to}`).then(response => {
        dispatch('updateWeekStatistic', response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })
    promises[promiseKey] = promise

    return promise
  },

  inviteUsers ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/invites', data).then(invitedUsers => {
        resolve(invitedUsers)
      }).catch(err => {
        reject(err)
      })
    })
  },
  inviteCompanyUsers ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`companies/${state.companyId}/invites/company_user`, data).then(invitedUsers => {
        resolve(invitedUsers)
      }).catch(err => {
        reject(err)
      })
    })
  },
  invitedUsers ({ commit }, companyId) {
    return new Promise(resolve => {
      HTTP.get('/invites?companyId=' + companyId).then(companyInvitedUsers => {
        commit(GET_COMPANY_INVITED_USERS, companyInvitedUsers)
        resolve(companyInvitedUsers)
      })
    })
  },
  cancelInvite ({ commit }, userId) {
    return new Promise(resolve => {
      HTTP.delete('/invites/' + userId, { data: { company_id: state.companyId } }).then(response => {
        resolve(response)
      })
    })
  },
  reinvite ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/invites', data).then(response => {
        resolve(response)
      })
    })
  },
  massInvites ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/invites/mass', data).then(response => {
        resolve(response)
      })
    })
  },

  // getCompanyFields ({ commit }) {
  //   return new Promise(resolve => {
  //     HTTP.get('/company-fields').then(fields => {
  //       commit(GET_COMPANY_FIELDS, fields)
  //       resolve(fields)
  //     })
  //   })
  // },
  getCompanies ({ commit }) {
    return new Promise(resolve => {
      HTTP.get('/companies').then(companies => {
        commit(GET_COMPANY_LIST, companies)
        resolve(companies)
      })
    })
  },
  getBillingAddresses ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get('/companies/' + id + '/billing_information').then(BillingAddress => {
        if (BillingAddress) {
          commit(GET_BILLING_ADDRESS, BillingAddress)
        }
        resolve(BillingAddress)
      })
    })
  },
  setBillingAddress ({ commit }, [companyId, id, data]) {
    return new Promise(resolve => {
      HTTP.post('/companies/' + companyId + '/billing_information', data).then(BillingAddress => {
        if (BillingAddress) {
          commit(GET_BILLING_ADDRESS, BillingAddress)
        }
        resolve(BillingAddress)
      })
    })
  },
  patchBillingAddress ({ commit }, [companyId, id, data]) {
    return new Promise(resolve => {
      HTTP.patch('/companies/' + companyId + '/billing_information/' + id, data).then(BillingAddress => {
        if (BillingAddress) {
          commit(GET_BILLING_ADDRESS, BillingAddress)
        }
        resolve(BillingAddress)
      })
    })
  },
  importShifts ({ commit }, [companyId, payload]) {
    const promiseKey = `/companies/${companyId}/import_shifts`

    if (promises[promiseKey]) {
      console.error('repeating import shifts')
      return promises[promiseKey]
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    let promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload, config).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getModulesByCompany ({ commit }) {
    const promiseKey = '/companies/' + state.companyId + '/modules'

    if (promises[promiseKey]) {
      console.info('repeating requests getModulesByCompany')
      return promises[promiseKey]
    }
    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + state.companyId + '/modules').then(modules => {
        commit(GET_MODULES_BY_COMPANY, [])
        commit(MODULES_IDS_BY_COMPANY, [])
        if (modules) {
          commit(GET_MODULES_BY_COMPANY, modules)
          commit(MODULES_IDS_BY_COMPANY, modules)
        }
        resolve(modules)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  setModulesByCompany ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + state.companyId + '/modules', data).then(modules => {
        commit(CLEAR_COMPANY)
        commit(SET_MODULES_BY_COMPANY, modules)
        resolve(modules)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editModuleByCompany ({ commit }, [moduleId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + state.companyId + '/modules/' + moduleId, data).then(modules => {
        resolve(modules)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getReferrals ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get(`/companies/${id}/referrals`).then(referrals => {
        resolve(referrals)
      })
    })
  },
  patchCompanyClientFields ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.patch('/companies/' + state.companyId + '/client/fields', data).then(card => {
        resolve(card)
      })
    })
  },
  getCompanyClientFields ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + state.companyId + '/client/fields').then(card => {
        resolve(card)
      }).catch(err => {
        reject(err)
      })
    })
  },
  searchByAddr ({ commit }, addr) {
    return new Promise(resolve => {
      HTTP.get('/companies/' + state.companyId + '/address-search?address=' + addr).then(card => {
        resolve(card)
      })
    })
  },
  searchByClient ({ commit }, client) {
    return new Promise(resolve => {
      HTTP.get('/companies/' + state.companyId + '/clients-search?client=' + client).then(card => {
        resolve(card)
      })
    })
  },
  getSalaryTemplate ({ commit }) {
    return new Promise(resolve => {
      HTTP.get('/companies/' + state.companyId + '/salary_template').then(settings => {
        resolve(settings)
      })
    })
  },
  getAttendance ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + state.companyId + '/attendance').then(settings => {
        resolve(settings)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateAttendance ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + state.companyId + '/attendance', data).then(settings => {
        resolve(settings)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCompanyLogs ({ commit }, payload) {
    const { companyId, from, to, page, perPage, logName, causer, action, workMode } = payload
    return new Promise((resolve, reject) => {
      let querry = `/companies/${companyId}/logs?time_from=${from}&time_to=${to}&page=${page}&per_page=${perPage}&work_mode=${workMode}`
      if (logName) {
        querry += `&log_name=${logName}`
      }
      if (causer) {
        querry += `&causer_id=${causer}`
      }
      if (action) {
        querry += `&description=${action}`
      }
      HTTP.get(querry)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err)
        })
    })
  },

  getCompanyPromos ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${state.companyId}/promos`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  storeCompanyPromo ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${state.companyId}/promos`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateCompanyPromo ({ commit }, [promoId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${state.companyId}/promos/${promoId}`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  destroyCompanyPromo ({ commit }, promoId) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${state.companyId}/promos/${promoId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTimeSheetReport ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${state.companyId}/stats/time_sheet` + payload, { responseType: 'blob' }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

  // setQuickBookAuthorization ({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     HTTP.post('/companies/' + state.companyId + '/quickbook', data).then(response => {
  //       resolve(response)
  //     }).catch(err => {
  //       reject(err)
  //     })
  //   })
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
