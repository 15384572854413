import {
  GET_ALERTS
} from '../mutation-types.js'
import { HTTP } from '@/api/http_utils'

const state = {
  Alerts: []
}

const getters = {
  Alerts: state => state.Alerts
}

const mutations = {
  [GET_ALERTS] (state, Alerts) {
    state.Alerts = Alerts
  }
}

const actions = {
  markAsRead ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`companies/${companyId}/notifications/${id || ''}`).then(alerts => {
        resolve(alerts)
      }).catch(err => {
        reject(err)
      })
    })
  },
  markAsReadType ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`companies/${companyId}/notifications`, payload).then(alerts => {
        resolve(alerts)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUnreadAlerts ({ commit }, [companyId, workMode]) {
    return new Promise((resolve, reject) => {
      const workModeQuery = workMode ? `?work_mode=${workMode}` : ''
      HTTP.get(`companies/${companyId}/notifications/unread` + workModeQuery).then(alerts => {
        if (alerts) {
          commit(GET_ALERTS, alerts)
          resolve(alerts)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  getAlertsCount ({ commit }, [companyId, workMode]) {
    return new Promise(resolve => {
      const workModeQuery = workMode ? `?work_mode=${workMode}` : ''
      HTTP.get(`companies/${companyId}/notifications/status` + workModeQuery).then(response => {
        if (response) {
          resolve(response)
        }
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
