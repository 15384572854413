// src/mixins/analyticsMixin.js

export default {
  methods: {
    /**
     * Send event to Google Analytics (GTM)
     */
    trackToGTM (event, category, action, label, additionalData = {}) {
      if (this.$gtm) {
        if (!this.$gtm.enabled()) {
          this.$gtm.enable(true)
        }
        this.$gtm.trackEvent({
          event,
          category,
          action,
          label,
          ...additionalData
        })
      } else {
        console.warn('GTM is not initialized.')
      }
    },

    /**
     * Send event to Crisp
     */
    trackToCrisp (eventName, eventData = {}) {
      if (this.$crisp) {
        this.$crisp.session.pushEvent(eventName, eventData)
      } else {
        console.warn('Crisp is not initialized.')
      }
    },

    /**
     * Universal method for recording an event in both systems
     */
    trackEvent ({ gtm, crisp }) {
      if (gtm) {
        this.trackToGTM(gtm.event, gtm.category, gtm.action, gtm.label, gtm.additionalData)
      }
      if (crisp) {
        this.trackToCrisp(crisp.eventName, crisp.eventData)
      }
    }
  }
}
